import React from 'react'
import AbstractPage from '../AbstractPage/AbstractPage'
import s from './AboutCatsText.module.css'
import TitleH2 from '../../BaseElements/TitleH2/TitleH2'

export const AboutCats = () => {
  return (
    <div>
      <AbstractPage children={<div className={s.wrapper}>
        <TitleH2 text={'О питомнике'}/>
        <div className={s.mainText}>
          <p>Наш питомник заслуженно является одним из самых крупных
            коллективных
            питомников в России. Он зарегистрирован по двум системам WCF и
            FIFE.</p>
          <p>Задача наших заводчиков состоит не только в популяризации сибирских
            кошек в России и за рубежом, ( мы вырастили 7 Чемпионов Мира , а
            выпускники питомника регулярно участвуют в выставках и побеждают ),
            мы
            стараемся получать гармонично развитых животных, соответствующих
            стандарту породы, имеющих хорошее здоровье и адекватную психику.
            Работа питомника основана на коллективном сотрудничестве заводчиков.
            Для серьезной племенной работы необходимо большое поголовье -
            различные линии, окрасы, генетика, пол. Всех вместе наших кошек
            можно
            увидеть только на страницах этого сайта.</p>
          <p>В жизни почти каждая кошка или кот имеет свой дом и своих любящих
            хозяев, является полноправным членом семьи. Кошка -
            высокоинтеллектуальное животное. Она должна чувствовать, что нужна и
            любима, у нее должно быть место, где она может отдыхать и
            чувствовать
            себя защищенной. Котята находятся с матерью как минимум до 2,5
            месяцев, а большинство покидает отчий дом только после полной
            вакцинации. Конечно же, кошачьей детворе требуется пространство для
            игр, полноценное питание и ежедневное общение с заводчиком, а кроме
            этого должны соблюдаться элементарные санитарно-гигиенические нормы
            -
            только в этом случае, возможно, вырастить полноценных и здоровых
            кошек-компаньонов, которые смогут быть не только великолепными
            участниками выставок, но и полноправными членами семьи, дарящими
            хозяину радость и теплоту общения.</p>
          <p>Благодаря правильно поставленной работе и активности владельцев
            наших
            племенных животных, мы достигли достаточно высоких результатов. Наши
            котята, бесспорно, пользуются большим спросом среди профессиональных
            заводчиков и любителей сибирских кошек, как в России, так и за
            рубежом. Нам не безразлична судьба наших выпускников, поэтому мы
            стараемся как можно больше узнать о будущем владельце котенка, чтобы
            иметь возможность помочь с выбором, стараемся поддерживать тесные
            отношения со всеми владельцами наших котят и оказываем им
            всестороннюю
            помощь.</p>
          <p>Уход и содержание кошки - это труд, но оплачивается он по-царски:
            теплом, лаской и нежностью пушистого любимца. И нет большей радости
            для заводчика, чем слова благодарности, которые он получает от
            владельцев своих воспитанников, которые приобрели у нас свое
            пушистое
            счастье.</p>
        </div>
        <div className={s.rep}><span>С уважением, руководитель питомника Ирина Гераймович</span>
        </div>

      </div>
      }/>
    </div>
  )
}

